import { screensConfig } from './screens'

export const startScreens = [
  /* PLOP_START_SCREENS */
  screensConfig.start_cycles,
  screensConfig.start_challenge,
  screensConfig.start_testimonial_gen_2,
  screensConfig.start_testimonial_gen_1,
  screensConfig.start_gender_type,
  screensConfig.start_gender_adhd_overeat_1,
  screensConfig.start_gender_adhd_overeat,
  screensConfig.start_gender_stress,
  screensConfig.start_gender_sugar,
  screensConfig.start_gender_adhd,
  screensConfig.start_gender_emotions,
  screensConfig.start_gender_eating_type,
  screensConfig.start_gender_chakra_v1,
  screensConfig.start_gender_chakra,
  screensConfig.start_food_relationship,
  screensConfig.start_age_chakra,
  screensConfig.start_age,
]

export const startScreensIds = startScreens.map(({ id }) => id)
