import { QuestionCategory } from 'shared/constants/header'

import { Screen, ScreenId } from './types'

import { KnowADHD } from '../providers/ClientDataProvider/constants/KnowADHD'

export const screens: { [key in ScreenId]: Partial<Screen> } = {
  // start screens
  start_age: {
    config: {
      step: false,
    },
  },

  // onboarding screens
  /* PLOP_ONBOARDING_SCREENS */
  ob_adhd_obesity: {
    config: {
      step: false,
    },
  },

  ob_adhd_bio_section: {
    config: {
      step: false,
    },
  },

  ob_eating_bhv_section: {
    config: {
      step: false,
    },
  },

  ob_adhd_adhd_section: {
    config: {
      step: false,
    },
  },

  start_cycles: {
    config: {
      step: false,
    },
  },

  start_challenge: {
    config: {
      step: false,
    },
  },

  ob_doctor_c: {},

  ob_adhd_personalized: {
    config: {
      step: false,
    },
  },

  ob_do_results: {
    config: {
      step: false,
    },
  },

  ob_adhd_test_score: {
    config: {
      step: false,
    },
  },

  ob_analyzing_results: {
    config: {
      step: false,
    },
  },

  ob_adhd_focus: {},

  ob_adhd_formal: {
    config: {
      step: false,
    },
  },

  ob_adhd_no_worries: {
    config: {
      step: false,
      filterFn: ({ knowADHD }) => {
        if (knowADHD) {
          return [KnowADHD.NOTHING, KnowADHD.BASICS].includes(knowADHD)
        }

        return true
      },
    },
  },

  ob_adhd_great_know: {
    config: {
      step: false,
      filterFn: ({ knowADHD }) => {
        if (knowADHD) {
          return knowADHD === KnowADHD.LOTS
        }

        return true
      },
    },
  },

  ob_adhd_welcome_1: {
    config: {
      step: false,
    },
  },

  ob_adhd_welcome: {
    config: {
      step: false,
    },
  },

  ob_adhd_yn_2: {},

  ob_adhd_yn_1: {},

  ob_adhd_diagnosed: {},

  ob_adhd_statement_9: {},

  ob_adhd_reread: {},

  ob_adhd_lose_track: {},

  ob_test_scores: {
    config: {
      step: false,
    },
  },

  ob_experience_section_feedback: {
    config: {
      step: false,
    },
  },

  ob_emotional_section_feedback: {
    config: {
      step: false,
    },
  },

  ob_stress_image: {},

  ob_bio_section_feedback: {
    config: {
      step: false,
    },
  },

  ob_pressure: {},

  ob_pushing: {},

  ob_guilt: {},

  ob_email_2: {
    config: {
      step: false,
    },
  },

  ob_experience_section: {
    config: {
      step: false,
    },
  },

  ob_emotional_section: {
    config: {
      step: false,
    },
  },

  ob_bio_section: {
    config: {
      step: false,
    },
  },

  ob_bt_benefits: {
    config: {
      step: false,
    },
  },

  ob_about_behavioral: {
    config: {
      step: false,
    },
  },

  ob_feedback_summary_emotions: {
    config: {
      step: false,
    },
  },

  ob_weight_gain_em_balance: {},

  ob_reach_goal: {
    config: {
      step: false,
    },
  },

  ob_food_relax: {},

  ob_bt_what_benefits: {},

  ob_know_behavioral: {},

  ob_unconscious: {},

  ob_base_statement_3: {},

  ob_base_statement_2: {},

  ob_base_statement_1: {},

  ob_self_improve: {},

  ob_em_4: {},

  ob_em_3: {},

  ob_em_2: {},

  start_testimonial_gen_2: {
    config: {
      step: false,
    },
  },

  start_testimonial_gen_1: {
    config: {
      step: false,
    },
  },

  ob_em_1: {},

  ob_adhd_graph: {
    config: {
      step: false,
    },
  },

  ob_weight_gain_feedback_adhd: {
    config: {
      step: false,
    },
  },

  feedback_summary_adhd: {
    config: {
      step: false,
    },
  },

  ob_feedback_sum_adhd: {},

  ob_email_adhd: {
    config: {
      step: false,
    },
  },

  ob_adhd_impulsivity: {
    config: {
      step: false,
    },
  },

  ob_add_goal_1: {},

  ob_add_improve: {},

  ob_adhd_statement_8: {},
  ob_adhd_statement_7: {},
  ob_adhd_statement_6: {},

  ob_adhd_statement_5: {},

  ob_adhd_statement_4: {},

  start_gender_type: {
    config: {
      step: false,
    },
  },

  start_gender_adhd_overeat_1: {
    config: {
      step: false,
    },
  },

  start_gender_adhd_overeat: {
    config: {
      step: false,
    },
  },

  start_gender_stress: {
    config: {
      step: false,
    },
  },

  ob_adhd_evidence: {
    config: {
      step: false,
    },
  },

  ob_adhd_inattention: {
    config: {
      step: false,
    },
  },

  ob_adhd_quiz: {
    config: {
      step: false,
    },
  },

  ob_about_adhd: {
    config: {
      step: false,
    },
  },

  ob_adhd_statement_3: {},

  ob_adhd_statement_2: {},

  ob_adhd_statement_1: {},

  ob_adhd_suspect: {},

  ob_goals_mix_adhd: {},

  ob_adhd_reason: {},

  ob_adhd_believe: {},

  ob_do_know_adhd: {},

  start_gender_sugar: {
    config: {
      step: false,
    },
  },

  start_gender_adhd: {
    config: {
      step: false,
    },
  },

  start_gender_emotions: {
    config: {
      step: false,
    },
  },

  start_gender_eating_type: {
    config: {
      step: false,
    },
  },

  start_gender_chakra_v1: {
    config: {
      step: false,
    },
  },

  ob_manipura: {
    config: {
      step: false,
    },
  },
  ob_svadhishthana: {
    config: {
      step: false,
    },
  },
  ob_muldhara: {
    config: {
      step: false,
    },
  },
  ob_vishuddha: {
    config: {
      step: false,
    },
  },

  ob_chakra_results: {
    config: {
      step: false,
    },
  },

  ob_motivational_blocker: {
    config: {
      step: false,
    },
  },

  ob_childhood_t_blocker: {
    config: {
      step: false,
    },
  },

  ob_psycological_blocker: {
    config: {
      step: false,
    },
  },

  ob_metabolical_blocker: {
    config: {
      step: false,
    },
  },

  start_gender_chakra: {
    config: {
      step: false,
    },
  },

  ob_back_issue_config: {},

  start_age_chakra: {},

  ob_good_news: {},

  ob_eating_bh: {},

  ob_graph_yoyo: {},

  ob_experts: {},

  ob_goal_plan: {},

  ob_med_proof: {},

  ob_no_pills: {},

  ob_graph_childhood_trauma: {},

  ob_associations_intro: {},

  ob_overcome_eating: {},

  ob_feedback_human: {},

  ob_map: {},

  ob_habitual_journey: {},

  ob_info_dieting: {},

  ob_graph_prediction_event: {},

  ob_psychological: {},

  ob_see_3: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_see_2: {},

  ob_see_1: {},

  ob_add_goal: {},

  ob_explore_add: {},

  ob_snack_time: {},

  ob_goal_mind: {},

  ob_sure_weightloss: {},

  ob_motivation_change: {},

  ob_recommended_medical: {},

  ob_previous_mental_health: {},

  ob_previous_attempts: {},

  ob_statement_3: {},

  ob_statement_2: {},

  ob_statement_1: {},

  ob_fitness_walking: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_pref_eat_place: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_d: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_better_habits: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_e: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_c: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_a: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_result: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_motivation: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_life_events: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  bad_habits: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fortunately: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_childhood_trauma: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_trust_feedback: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_sleep: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_creating_3: {
    config: {
      step: false,
    },
  },

  ob_health_conditions: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_medications: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_describes_best: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_water: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_walking: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_stairs: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_active: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_job_load: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_meals_feel: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_welcome_to_md: {
    config: {
      step: false,
    },
  },

  ob_creating_b: {
    config: {
      step: false,
    },
  },

  ob_time_dinner: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_lunch: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_time_breakfast: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_happy_weight: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_body_type_a: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_goals_mix_b: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_gender: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_social_proof: {},

  ob_occasion_result: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_current_body_type_a: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  feedback_summary_fitlevel: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_tags: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_target_zones_b: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_fitness_level_a: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_weight_gain_feedback: {
    config: {
      step: false,
    },
  },

  ob_mindea_different: {
    config: {
      step: false,
    },
  },

  ob_lifestyle: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_gain: {
    config: {
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_health_issues: {
    config: {
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_diets: {
    config: {
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_feedback_skip_funnel: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_create_behavior_profile: {
    config: {
      step: false,
      replace: true,
    },
  },

  ob_alt_email_input: {
    config: {
      step: false,
    },
  },

  start_food_relationship: {
    config: {
      step: false,
    },
  },

  ob_nhe_quiz_external_feedback: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_feedback: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_feedback: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_email_consent: {
    config: {
      step: false,
    },
  },

  ob_weight_graph_nhe: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nutrition_cravings: {
    config: {
      step: true,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_feedback_nutrition_restrictive: {
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_hungry_time: {
    config: {
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_stress_handle: {
    config: {
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_meal_type: {
    config: {
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_eat_habits: {
    config: {
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_st_fit_wl: {
    config: {
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_behavior_push: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_guilt: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_social: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_trigger: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_plate: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_behavior_st_socialize: {
    config: {
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_bio_sex: {
    config: {
      questionCategory: QuestionCategory.BIO,
    },
  },

  ob_weight_loss_pace: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_focus: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_program_ready: {
    config: {
      step: false,
    },
  },

  ob_feedback_nutrition_emotions: {
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nutrition_start: {
    config: {
      step: false,
      questionCategory: QuestionCategory.NUTRITION,
    },
  },

  ob_nhe_quiz_start: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_feedback_behavior_end: {
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_bio: {
    config: {
      step: false,
      questionCategory: QuestionCategory.BEHAVIOR_AND_EMOTIONS,
    },
  },

  ob_feedback_goal_focus: {
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_feedback_goal_set: {
    config: {
      step: false,
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_nhe_quiz_external_start: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_start: {
    config: {
      step: false,
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_10: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_9: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_8: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_7: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_6: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_5: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_4: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_3: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_2: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_external_1: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_13: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_12: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_11: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_10: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_9: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_8: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_7: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_6: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_5: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_4: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_3: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_2: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_emotional_1: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_3: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_10: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_9: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_8: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_7: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_6: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_5: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_4: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_2: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },

  ob_nhe_quiz_restrained_1: {
    config: {
      questionCategory: QuestionCategory.EATING_PROFILE,
    },
  },
  ob_nhe_quiz_result: {
    config: {
      step: false,
    },
  },
  ob_create_program_final: {
    config: {
      step: false,
      replace: true,
    },
  },
  ob_feedback_create_program_screens: {
    config: {
      step: false,
    },
  },
  ob_weight_graph: {
    config: {
      step: false,
    },
  },
  ob_email_input: {
    config: {
      step: false,
    },
  },

  ob_bio_height: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_bio_age: {
    config: {
      questionCategory: QuestionCategory.BIO,
    },
  },
  ob_weight: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_target_weight: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_occasion: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },
  ob_feedback_nutrition_eat_all: {
    config: {
      step: false,
    },
  },
  ob_nhe_quiz_emotional_start: {
    config: {
      step: false,
    },
  },
  ob_bio_feedback: {
    config: {
      step: false,
    },
  },
  ob_feedback_health_goal: {
    config: {
      step: false,
    },
  },

  ob_health_goal_select: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },

  ob_weight_loss_motivation: {
    config: {
      questionCategory: QuestionCategory.GOAL,
    },
  },

  // payment screens
  payment_screen: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_screen_2: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_base: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_base_copy: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_chakra: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_adhd: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_habits: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_shorten: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  payment_discount: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_foxtrot',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  offer_a5: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  upsell_1: {
    config: {
      step: false,
      replace: true,
      checkoutScreenId: 'checkout_golf',
      motivationScreenId: 'payment_exit_intent',
    },
  },

  // post checkout screens

  ob_payment_success_register: {
    config: {
      step: false,
      replace: true,
    },
  },

  ob_funnel_done_thank_you: {
    config: {
      step: false,
    },
  },
}

export const screensConfig = Object.entries(screens)
  .map(([key, value]) => ({
    [key]: {
      id: key,
      url: '/' + key,
      config: { step: true, ...value.config },
    },
  }))
  .reduce((acc, value) => Object.assign(acc, value), {}) as unknown as { [key in ScreenId]: Screen }

export const filterSerializableScreenProps = (screen: Screen) => {
  const { config } = screen

  // Not serializable
  if ('filterFn' in config) {
    delete config['filterFn']
  }

  return {
    ...screen,
    config,
  }
}
